import {track} from "@amplitude/analytics-browser";
import {
  faExclamationTriangle,
  faSearch,
  faCalendarDays,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Container, NumberInput, Text} from "@mantine/core";
import {DatesProvider, DatePickerInput} from "@mantine/dates";
import dayjs from "dayjs";
import {Formik, FormikErrors, getIn, setIn} from "formik";
import {isEmpty} from "lodash";
import type {NextPage} from "next";
import Head from "next/head";
import Link from "next/link";
import {useRouter} from "next/router";
import React, {useEffect} from "react";

import classes from "./index.module.scss";
import FeatureContainer from "../components/FeatureContainer";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import PlacePicker from "../components/PlacePicker";
import TextCycler from "../components/TextCycler";
import {APP_NAME, APP_DESCRIPTION, DEFAULT_LOCATION_ZOOM} from "../constants";
import {getLogger} from "../lib/logging";
import {searchStateToQueryString} from "../lib/search";
import type {SearchState} from "../lib/types";

const log = getLogger(__filename);

// Adjectives for the "Stay at ... camp site"
const ADJECTIVES: string[] = [
  "your ideal",
  "an amazing",
  "a cool",
  "a wonderful",
  "a nice",
  "an excellent",
  "a local",
  "a remote",
  "the best",
  "a beautiful",
  "your favorite",
];

/**
 * Home page
 *
 * Shows initial search input form and marketing info
 */
export default function HomePage({}: NextPage & {whyDidYouRender?: boolean}) {
  const router = useRouter();
  const today = dayjs();
  const dateFormat = "YYYY-MM-DD";
  const initialSearchState: Partial<SearchState> = {};

  useEffect(() => {
    track("Home Page Viewed");
  }, []);

  return (
    <>
      <Head>
        <meta name="description" content={APP_DESCRIPTION} />

        <meta property="og:title" content={APP_NAME} />
        <meta property="og:description" content={APP_DESCRIPTION} />
      </Head>

      <main className={classes.layoutMain}>
        <Hero
          className={classes.hero}
          title={
            <div className={classes.stayAt}>
              Stay at{" "}
              <Text component="span" inherit className={classes.highlight}>
                <TextCycler textList={ADJECTIVES} delaySeconds={8} />{" "}
              </Text>
              camp site
            </div>
          }
          description="reserve, camp, glamp, rv, boondock, backpack"
          backgroundImageUrl="/images/hero_backgrounds/home.jpg">
          <Formik<Partial<SearchState>>
            initialValues={initialSearchState}
            validate={values => {
              let errors: FormikErrors<Partial<SearchState>> = {};

              // Validate date ranges and dependent fields
              const filterMinDate = getIn(
                values,
                "searchInput.filter.dates.min"
              );
              const filterMaxDate = getIn(
                values,
                "searchInput.filter.dates.max"
              );

              if (filterMinDate || filterMaxDate) {
                if (!filterMinDate || !filterMaxDate) {
                  errors = setIn(
                    errors,
                    "searchInput.filter.dates.min",
                    "Both begin and end dates must be entered together"
                  );
                } else {
                  const beginDate = dayjs(filterMinDate, dateFormat);
                  const endDate = dayjs(filterMaxDate, dateFormat);

                  // today <= dates.min
                  if (beginDate.isBefore(today.startOf("day"))) {
                    errors = setIn(
                      errors,
                      "searchInput.filter.dates.min",
                      `Begin date is in the past: ${beginDate.format("L")}`
                    );
                  }

                  // dates.min <= dates.max
                  if (!endDate.isSameOrAfter(beginDate)) {
                    errors = setIn(
                      errors,
                      "searchInput.filter.dates.min",
                      "End date must be greater than or the same as begin date"
                    );
                  }
                }
              }

              // Validate people
              const filterPeople = getIn(
                values,
                "searchInput.filter.people.max"
              );
              if (filterPeople && filterPeople < 1) {
                errors = setIn(
                  errors,
                  "searchInput.filter.people.max",
                  "Group size must be at least 1"
                );
              }

              const location = getIn(values, "center");
              if (!location || !location.latitude || !location.longitude) {
                errors = setIn(errors, "center", "Location is required");
              }

              return errors;
            }}
            onSubmit={async (values, {setSubmitting}) => {
              await router.push(`/search?${searchStateToQueryString(values)}`);

              setSubmitting(false);
            }}>
            {({
              handleSubmit,
              values,
              errors,
              isValidating,
              isSubmitting,
              submitCount,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} className={classes.controls}>
                <PlacePicker
                  size="lg"
                  placeholder="Where to?"
                  autoFocus
                  onSelection={async result => {
                    if (!result.location) {
                      log.warn(
                        `No location on selected ${result.type} result ${result.id}`
                      );
                      return;
                    }

                    await setFieldValue("center", result.location);
                    await setFieldValue(
                      "searchInput.zoom",
                      DEFAULT_LOCATION_ZOOM
                    );
                  }}
                />
                <DatesProvider settings={{firstDayOfWeek: 0}}>
                  <DatePickerInput
                    size="lg"
                    type="range"
                    placeholder="Add dates?"
                    dropdownType="modal"
                    numberOfColumns={2}
                    classNames={{wrapper: classes.datesInputWrapper}}
                    error={
                      submitCount > 0 &&
                      getIn(errors, "searchInput.filter.dates.min")
                    }
                    defaultDate={today.toDate()}
                    excludeDate={date => {
                      const dateObj = dayjs(date);
                      return dateObj.isBefore(today.startOf("day"));
                    }}
                    valueFormat="M/D/YY"
                    value={[
                      getIn(values, "searchInput.filter.dates.min")
                        ? dayjs(
                            getIn(values, "searchInput.filter.dates.min"),
                            dateFormat
                          ).toDate()
                        : null,
                      getIn(values, "searchInput.filter.dates.max")
                        ? dayjs(
                            getIn(values, "searchInput.filter.dates.max"),
                            dateFormat
                          ).toDate()
                        : null,
                    ]}
                    onChange={value => {
                      const [beginDate, endDate] = value;
                      setFieldValue(
                        "searchInput.filter.dates.min",
                        beginDate ? dayjs(beginDate).format(dateFormat) : null
                      );
                      setFieldValue(
                        "searchInput.filter.dates.max",
                        endDate ? dayjs(endDate).format(dateFormat) : null
                      );
                    }}
                    clearable
                    leftSection={<FontAwesomeIcon icon={faCalendarDays} />}
                    leftSectionPointerEvents="none"
                  />
                </DatesProvider>

                <NumberInput
                  size="lg"
                  name="searchInput.filter.people.max"
                  placeholder="Add guests?"
                  classNames={{wrapper: classes.peopleInputWrapper}}
                  error={
                    submitCount > 0 && getIn(errors, "searchInput.people.max")
                  }
                  min={1}
                  value={getIn(values, "searchInput.filter.people.max") || ""}
                  onChange={value =>
                    setFieldValue("searchInput.filter.people.max", value)
                  }
                  leftSection={<FontAwesomeIcon icon={faPeopleGroup} />}
                />

                <Button
                  size="lg"
                  variant="primary"
                  onClick={() => handleSubmit()}
                  disabled={isValidating}
                  loading={isSubmitting}
                  leftSection={
                    !isEmpty(errors) && submitCount > 0 ? (
                      <FontAwesomeIcon icon={faExclamationTriangle} beatFade />
                    ) : (
                      <FontAwesomeIcon icon={faSearch} />
                    )
                  }>
                  Search
                </Button>
              </form>
            )}
          </Formik>

          <Container className={classes.browse}>
            <Text size="sm">
              Not sure? <Link href="/search">Browse the map</Link>
            </Text>
          </Container>
        </Hero>

        <div className={classes.content}>
          <FeatureContainer />
        </div>

        <Footer className={classes.footer} />
      </main>
    </>
  );
}

// HomePage.whyDidYouRender = true;
